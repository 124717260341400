import { Controller } from "@hotwired/stimulus"

const axios = require('axios');

// The element should have following attributes:
// data-controller="form-contents--event-checkbox" -> To define the controller, in outer element of all checkboxes
// data-action="click->form-contents--event-checkbox#disableCheckboxes" -> The event to trigger the search

export default class extends Controller {
  connect() {
    this.checkRbeIfNeeded();
    this.checkAndDisableExistingEvents();
    this.disableCheckboxes()
  }

  disableCheckboxes() {
    const checkboxes = [...document.querySelectorAll('input[type="checkbox"]')];

    axios.get('/form_content_steps/filter_checkboxes.json',
              { params: {
                // Get all checkboxes ids on the page
                ids: checkboxes.map(item => item.id),
                // Get all selected checkboxes
                checked: [...document.querySelectorAll('input[type="checkbox"]:checked')].map(item => item.id)
              } })
      .then(function (response) {
        // handle success
        // console.log(response.data);
        checkboxes.map(item => item.removeAttribute('disabled'));
        [...document.querySelectorAll('label')].map(item => item.classList.add('text-gray-900'));
        [...document.querySelectorAll('label')].map(item => item.classList.remove('line-through', 'text-gray-300'));

        response.data.forEach(element => {
          document.getElementById(element).setAttribute('disabled', 'disabled');
          document.getElementById(`${element}-label`).classList.add('line-through', 'text-gray-300');
          document.getElementById(`${element}-label`).classList.remove('text-gray-900');
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  // Not compatible with IE 11
  checkRbeIfNeeded() {
    var urlParams = new URLSearchParams(window.location.search);
    var eventType = urlParams.get('from');

    if(eventType !== 'rbe') return false;

    document.getElementById('29M').click();
  }

  // Look at existing events
  // Check and disable them in the page
  checkAndDisableExistingEvents() {
    if(!document.getElementById('existing_events')) return;

    const checkboxes = document.querySelectorAll("input[type='checkbox']");

    const existing = document.getElementById('existing_events').value.split(',');
    existing.forEach(event => {
      checkboxes.forEach(checkbox => {
        let condition = false;
        if(checkbox.id.includes("-")) {
          const events = checkbox.id.split('-');
          condition = checkbox.id == events[0] && existing.includes(events[1]);
        } else {
          condition = checkbox.id == event;
        }
        if(condition === true) {
          checkbox.checked = true;
          // Hide it so it remains on page, this is mandatory for the filtering to work
          checkbox.style.display = 'none';
          // Replace checkbox by a OK check, otherwise when cliking new event it enables the checkbox
          checkbox.insertAdjacentHTML('afterend', '<i class="fas fa-check text-success"></i>');
          // Remove the label for to prevent checkbox clicking through label
          // https://demat-facile.monday.com/boards/327646012/pulses/5324939266
          document.getElementById(`${checkbox.id}-label`).removeAttribute('for');
        }
      });
    });
  }
}